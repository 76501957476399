import React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Testimonial from "../components/testimonial"

import { colors } from "../styles/colors"
import { borders, shadows } from "../styles"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  background-color: transparent;

  & ${Testimonial} {
    padding: 2rem 6rem;
    margin: 0;
    max-width: 100%;

    @media only screen and (max-width: 767px) {
      padding: 2rem 0.5rem;
      margin: auto;
      max-width: 80%;
    }
  }
`

const TestimonialCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  border: ${borders.standard};
  border-radius: 0;
  box-shadow: ${shadows.standard};

  &:nth-child(odd) {
    background-color: ${colors.darkGrey};
  }
  &:nth-child(even) {
    background-color: ${colors.primary};
  }

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

export default class TestimonialsPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.pageStartRef = React.createRef()
  }

  componentDidMount() {
    if (this.pageStartRef.current) {
      this.pageStartRef.current.scrollIntoView({ behaviour: "smooth" })
    }
  }

  render() {
    const testimonials = this.props.data.testimonials.edges.map((edge) => {
      return {
        author: edge.node.frontmatter.author,
        body: edge.node.rawMarkdownBody,
      }
    })

    return (
      <Layout>
        <Seo
          title="Testimonials | Sing Smart Studios | Townsville Singing School"
          keywords={[`testimonials`, `reviews`]}
        />
        <h1 style={{ textAlign: "center" }} ref={this.pageStartRef}>
          Testimonials
        </h1>
        <Container>
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.author}>
              <Testimonial>
                <p>{testimonial.body}</p>
                <span>{testimonial.author}</span>
              </Testimonial>
            </TestimonialCard>
          ))}
        </Container>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/markdown/testimonials/" } }
    ) {
      edges {
        node {
          frontmatter {
            author
          }
          rawMarkdownBody
        }
      }
    }
  }
`
